<template>
  <container-list-extend title="Fournisseur">
    <template v-slot:title>
      <div class="p-1 flex-grow-1 d-flex justify-content-between" v-if="fournisseur.description">
        <div>{{ fournisseur.description }}</div>
      </div>
      <div class="p-1 flex-grow-1 text-primary" v-if="!fournisseur.description">Nouveau fournisseur</div>
    </template>
    <template v-slot:form>
      <div class="d-flex flex-column flex-lg-row">
        <base-form
          class="w-100"
          deleteBtn
          @click="updateFournisseur(fournisseur)"
          @delete="deleteFournisseur(fournisseur)"
          :loading="loading"
        >
          <base-form-row row>
            <BaseInput
              class
              v-model="fournisseur.description"
              inputText="Description"
              :errors="feedback.description"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <base-select
              inputText="Etat"
              v-model.number="etat"
              :options="[
                { id: 0, description: 'Inactif' },
                { id: 1, description: 'Actif' },
              ]"
            ></base-select>
          </base-form-row>
          <base-form-row row>
            <BaseInput
              class
              v-model="fournisseur.raison_sociale"
              inputText="Raison sociale"
              :errors="feedback.raison_sociale"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <BaseInput
              class
              v-model="fournisseur.activite"
              inputText="Activité"
              :errors="feedback.activite"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <BaseInput
              class
              v-model="fournisseur.adresse"
              inputText="Adresse"
              :errors="feedback.adresse"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <BaseInput
              class
              v-model="fournisseur.telephone"
              inputText="Téléphone"
              :errors="feedback.telephone"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <BaseInput
              class
              v-model="fournisseur.type_service"
              inputText="Service(s) rendu(s) à Arkadia"
              :errors="feedback.type_service"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <base-input-area
              v-model="fournisseur.commentaire"
              text="Commentaire"
              :errors="feedback.commentaire"
            ></base-input-area>
          </base-form-row>
        </base-form>
        <div class="pl-3 pr-3 w-100">
          <uploader
            v-if="fournisseur.id"
            class="mt-5"
            id="document_fournisseur"
            url="/upload/document"
            title="Vos fichiers"
            :file_system="'FOURNISSEUR'"
            :file_field="'materiel_fournisseur'"
            :file_key="fournisseur.id"
          ></uploader>
          <div>
            <BaseToolBar title="Evaluations" subtitle="Liste des évaluations du fournisseur">
              <BaseButton
                class="btn btn-primary ml-3"
                v-tooltip="'Ajouter une évaluation'"
                @click="storeFournisseurEval"
                :loading="loading"
                icon="plus"
              ></BaseButton>
            </BaseToolBar>
            <div>
              <div v-for="evaluation in evaluationsfiltered" :key="evaluation.id">
                <fournisseurs-item-eval :evaluation="evaluation"></fournisseurs-item-eval>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </container-list-extend>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";
import { mapMultiRowFields } from "vuex-map-fields";

import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import Uploader from "@/components/bases/Uploader.vue";
import FournisseursItemEval from "@/components/materiels/FournisseursItemEval.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import BaseButton from "@/components/bases/Button.vue";
import BaseSelect from "@/components/bases/Select.vue";

export default {
  name: "FournisseursItem",
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInput,
    BaseInputArea,
    Uploader,
    FournisseursItemEval,
    BaseToolBar,
    BaseButton,
    BaseSelect,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      etat: this.fournisseur.etat,
    };
  },
  props: ["fournisseur"],
  computed: {
    ...mapGetters({ data: "materiel/data" }),
    ...mapMultiRowFields("materiel", ["evaluations"]),
    evaluationsfiltered: function () {
      return this.evaluations.filter((evaluation) => evaluation.fournisseur_id === this.fournisseur.id);
    },
  },
  methods: {
    ...mapActions({
      _updateFournisseur: "materiel/updateFournisseur",
      _deleteFournisseur: "materiel/deleteFournisseur",
      _storeFournisseurEval: "materiel/createFournisseurEval",
    }),
    updateFournisseur: function (fournisseur) {
      this.feedback = {};
      this.loading = true;
      this.fournisseur.etat = this.etat;
      this._updateFournisseur(fournisseur)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteFournisseur: function (fournisseur) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteFournisseur(fournisseur)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    storeFournisseurEval: function () {
      this.feedback = {};
      this.loading = true;
      this._storeFournisseurEval(this.fournisseur)
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="css"></style>
